import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import '../styles/global.scss'
import '../styles/header.scss'
import Logo from '../vectors/logo.svg'
import Miller from '../vectors/mk-logo.svg'
import styled from 'styled-components'

const MenuLinks = styled.div`
    display: ${({nav}) => (nav ? "flex" : "none")}
`

const Header = () => {

    const [nav, showNav] = useState(false);

    const handleClick = () => {
        showNav(!nav);
        scrollFix();
    }

    const scrollFix = () => {
        var scrollable = document.getElementsByTagName("html")[0];
        if (window.getComputedStyle(scrollable).getPropertyValue('overflow') === 'scroll') {
            scrollable.style.overflow = "hidden";
        } else {
            scrollable.style.overflow = "scroll";
        }
    }

    return (
        <div id="header">
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap" rel="stylesheet"/>
            </Helmet>
            <header id="header-responsive" className="container">
                <div className="header-logo">
                    <Link to='/'><Logo /></Link>
                    <span className='sub-logo'><Miller/></span>
                </div>
                <div className="header-nav-desktop">
                    <nav>
                        <ul>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/our-story'>Story</Link></li>
                            <li><Link to='/solutions'>Solutions</Link></li>
                            <li><Link to='/contact'>Contact</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className="header-nav-mobile">
                    <div id="menuicons" onClick={() => {handleClick()}}>
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </header>
            <MenuLinks id="menulinks" nav={nav}>
                <nav>
                    <ul>
                        <li><a href='/' onClick={() => {scrollFix()}}>Home</a></li>
                        <li><a href='/our-story' onClick={() => {scrollFix()}}>Story</a></li>
                        <li><a href='/solutions' onClick={() => {scrollFix()}}>Solutions</a></li>
                        <li><a href='/contact' onClick={() => {scrollFix()}}>Contact</a></li>
                    </ul>
                </nav>
            </MenuLinks>
        </div>
    )
}

export default Header