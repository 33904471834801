import React from 'react'
import { Link } from 'gatsby'
import Logo from '../vectors/logo.svg'
import '../styles/footer.scss'

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="column-footer">
                    <div className="footer-wide">
                        <p className="footer-title"><br /></p>
                        <div className="footer-logo"><Logo /></div>
                        <p className="footer-text mt-3">© 2022 Dundas Interiors. All rights reserved.</p>
                    </div>
                    <div className="footer-normal">
                        <p className="footer-title">Solutions</p>
                        <ul className='ps-0'>
                            <li className="footer-text">Healthcare</li>
                            <li className="footer-text">Education</li>
                            <li className="footer-text">Government</li>
                            <li className="footer-text">Business</li>
                            <li className="footer-text">Home office</li>
                        </ul>
                    </div>
                    <div className="footer-normal">
                        <p className="footer-title">Brands</p>
                        <ul className='ps-0'>
                            <li className="footer-text">Herman Miller</li>
                            <li className="footer-text">Nemschoff</li>
                            <li className="footer-text">Geiger</li>
                            <li className="footer-text">HAY</li>
                            <li className="footer-text">Maars</li>
                        </ul>
                    </div>
                    <div className="footer-normal">
                        <p className="footer-title">Contact</p>
                        <ul className='ps-0'>
                            <li className="footer-text">3730 N Montana Ave</li>
                            <li className="footer-text">Helena, MT 59602</li>
                            <li className="footer-text"><br /></li>
                            <li className="footer-text"><a href='mailto:contact_us@dundasinteriors.com' className="text-black border-bottom border-2 border-black">contact_us@dundasinteriors.com</a></li>
                            <li className="footer-text"><a href='tel:4064423448' className="text-black border-bottom border-2 border-black">(406) 442-3448</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer